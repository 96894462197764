import React, { useReducer, useRef, useEffect, useState } from "react"
import moment from "moment"
import { Button, Input, Form, Spinner } from "reactstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { emailPattern } from "../../../utils/form.utils"
import { faPaperPlane, faUserCircle } from "@fortawesome/free-solid-svg-icons"
import messageStart from "../../../assets/images/messge-start.svg"
import "./MessagesSpace.scss"
const initialState = {
  form: { message: "", email: "" },
  success: true,
  error: {
    message: "",
  },
}

const reducer = (state, action) => {
  switch (action.type) {
    case "changeError":
      return { ...state, error: { ...state.error, ...action.payload.error } }
    case "changeSuccess":
      return { ...state, error: { ...state.success, ...action.payload.success } }
    case "changeForm":
      return { ...state, form: action.payload.form }
    default:
      throw new Error()
  }
}

const MessagesSpace = ({
  status,
  messages,
  sendMessage,
  isWebsocketOpen,
  socket,
  onReceiveMessage,
  isUnauthFlow = false,
  userData,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState),
    [isLoading, setIsLoading] = useState(true),
    [lastMessage, setLastMessage] = useState([]),
    [timer, setTimer] = useState(),
    [isValid, setIsValid] = useState(false),
    [isEmailSubmitted, setIsEmailSubmitted] = useState(false),
    { form, error } = state,
    messagesSpaceRef = useRef()
  let lastDate = null,
    prevMsgSender = "agent",
    currMsgSender = "agent"

  const handleChange = (event) => {
    const { value } = event.target
    dispatch({ type: "changeForm", payload: { form: { ...form, message: value } } })
  }

  const handleChangeEmail = (event) => {
    const { value } = event.target

    const isValidEmail = emailPattern.test(value)

    dispatch({
      type: "changeForm",
      payload: { form: { ...form, email: value } },
    })
    if (!isValidEmail) {
      setIsValid(false)
      dispatch({
        type: "changeError",
        payload: { error: { email: "Please enter a valid email address." } },
      })
    } else {
      setIsValid(true)
      dispatch({
        type: "changeError",
        payload: { error: { email: "" } }, // clear error if valid
      })
    }
  }
  const onSocketMessage = (e) => {
    const recived_comments = JSON.parse(e.data)
    if (!recived_comments) return false
    setLastMessage(recived_comments)
  }

  const submitEmail = (event) => {
    event.preventDefault()
    sendMessage({ message: form.email })
    setIsEmailSubmitted(true)
    localStorage.setItem("email-submitted", "true")
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    const body = {
      message: form.message,
    }
    sendMessage(body)
    dispatch({ type: "changeForm", payload: { form: { ...form, message: "" } } })
  }
  useEffect(() => {
    socket.onmessage = onSocketMessage

    const emailSubmitted = localStorage.getItem("email-submitted") === "true"
    setIsEmailSubmitted(emailSubmitted)
  }, [])

  useEffect(() => {
    onReceiveMessage(lastMessage)
  }, [lastMessage])

  useEffect(() => {
    const chatToken = localStorage.getItem("chat-token")
    if (isUnauthFlow && chatToken && isWebsocketOpen && !messages.length) {
      sendMessage({ chatToken })
    }
    if (isWebsocketOpen) {
      setIsLoading(false)
    }
  }, [isWebsocketOpen])

  return (
    <>
      <div ref={messagesSpaceRef} className="messages-space flex-column overflow-auto popover-body">
        <div className="d-flex align-items-center flex-column">
          <img src={messageStart} alt="Billgist messages" className="w-50" />
          <p className="text-center fw-bold">Too confusing do not worry we are here to help</p>
        </div>
        {isLoading || (!isWebsocketOpen && status === "pending") ? (
          <div className="d-flex justify-content-center">
            <Spinner color="primary">Loading...</Spinner>
          </div>
        ) : (
          <>
            {messages.length ? (
              <>
                {messages.map((element, index) => {
                  const showDate = !lastDate || !moment(element.timestamp).isSame(lastDate, "Day")
                  const isToday = moment(element.timestamp).isSame(moment.now(), "Day")
                  if (showDate) {
                    lastDate = moment(element.timestamp)
                  }
                  if (index > 0) {
                    prevMsgSender = currMsgSender
                  }
                  currMsgSender = element.agent ? "agent" : "user"

                  return (
                    <div className="comment" key={element.m_id}>
                      {(showDate || isToday) && (
                        <div>
                          <p
                            className={`time mb-0 mt-2 ${
                              isToday
                                ? element.agent
                                  ? "text-align-left ms-5"
                                  : "text-align-right me-5"
                                : "d-flex justify-content-center"
                            }`}
                          >
                            {isToday
                              ? moment(element.timestamp).format("LT")
                              : showDate && moment(element.timestamp).format("MMMM D, YYYY")}
                          </p>
                        </div>
                      )}
                      <div className={`${element.agent ? "agent-space" : "user-space"}`}>
                        {showDate || isToday || prevMsgSender !== currMsgSender ? (
                          element.agent ? (
                            <FontAwesomeIcon icon={faUserCircle} size="2x" className="pl-1" />
                          ) : userData.image ? (
                            <img
                              src={userData.image.src}
                              className="user-image rounded-circle"
                              width="30"
                              height="30"
                              alt="user profile avatar"
                            />
                          ) : (
                            <FontAwesomeIcon icon={faUserCircle} size="2x" className="pl-1" />
                          )
                        ) : null}
                        <p
                          className={`message ${element.agent ? "agent-message" : "user-message"} mb-1 ${
                            !showDate && !isToday && prevMsgSender === currMsgSender
                              ? element.agent
                                ? "sub-msg-ms"
                                : "sub-msg-me"
                              : "ms-2 me-1"
                          }`}
                        >
                          {element.comment}
                        </p>
                      </div>
                    </div>
                  )
                })}
              </>
            ) : (
              <>
                {!isEmailSubmitted && isUnauthFlow && (
                  <div className="comment">
                    <p className="mb-1 w-100">
                      Please provide an email before sending a message so that we can get back to you if there is no
                      available representative.
                    </p>
                    <Form onSubmit={submitEmail}>
                      <Input
                        value={form.email}
                        invalid={!!error.email}
                        type="email"
                        className="email-input"
                        onChange={handleChangeEmail}
                      />
                      <Button
                        disabled={!form.email.length || !isValid}
                        color="primary text-light p-1 mt-1 w-50 float-end"
                      >
                        Submit
                      </Button>
                    </Form>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      <Form onSubmit={onSubmit} className="d-flex">
        <Input
          onChange={handleChange}
          className="border-top-left-0 border-top-right-0 border-bottom-right-0 rm-box-shadow"
          type="text"
          name="message"
          id="message"
          placeholder="Type a message"
          value={form.message}
          invalid={!!error.message}
        />
        <Button
          disabled={!form.message.length || !isWebsocketOpen || (isUnauthFlow && !isEmailSubmitted)}
          color="primary text-light send-button"
        >
          <FontAwesomeIcon icon={faPaperPlane} />
        </Button>
      </Form>
    </>
  )
}

export default MessagesSpace
