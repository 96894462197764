import React, { useContext, useState, useCallback, useEffect } from "react"
import { Button, PopoverHeader, PopoverBody, Popover, Spinner } from "reactstrap"
import { PopupModal, useCalendlyEventListener } from "react-calendly"
import { faTimes, faCommentAlt, faSquareXmark, faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import helpSvg from "../../assets/images/IT-Support_Two Color.svg"
import { UserContext } from "../../contexts/UserContext"
import "./integrationHelp.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"
import MessagesSpace from "./MessagesSpace"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import IntegrationHelpMessages from "../../utils/apis/integrationHelp"
import PMSchedule from "../../utils/apis/pmSchedule"

const IntegrationHelp = ({ isUnauthFlow = false }) => {
  const [cancelModal, setCancelModal] = useState(false),
    [isSuggestionOpen, setIsSuggestionOpen] = useState(false),
    { data } = useContext(UserContext),
    [isInChat, setIsInChat] = useState(false),
    [chat, setChat] = useState([]),
    [websocket, setWebsocket] = useState({}),
    [isWebsocketOpen, setIsWebsocketOpen] = useState(false),
    [isOpen, setIsOpen] = useState(false),
    navigate = useNavigate(),
    { status: PMScheduleStatus, data: PMScheduleData } = useQuery({
      queryKey: ["schedule"],
      queryFn: () => PMSchedule.get(),
    }),
    { status } = useQuery({
      queryKey: ["messages", data.item_id],
      queryFn: () => IntegrationHelpMessages.query(data.item_id),
      enabled: Boolean(data.item_id),
      onSuccess: (data) => {
        setChat([...data.comments])
      },
      retry: (_failureCount, error) =>
        error && (error.message === "BadRequestError: Subscription Expired" || error.message === "Subscription Expired")
          ? false
          : 3,
      refetchOnWindowFocus: false,
    })

  const createWebsocket = useCallback((queryParams) => {
    const WEBSOCKET_URL = `${IntegrationHelpMessages.socketURL()}?${queryParams}`
    const ws = new WebSocket(WEBSOCKET_URL)
    ws.onopen = () => setIsWebsocketOpen(true)
    setWebsocket(ws)
  }, [])

  const updateSchedule = (data) => {
    return PMSchedule.update(data)
  }

  const useUpdateSchedule = () => {
    const queryClient = useQueryClient()
    return useMutation({
      mutationFn: updateSchedule,
      onSuccess: () => {
        setIsOpen(false)
        queryClient.invalidateQueries({ queryKey: ["schedule"] })
      },
    })
  }

  function getCurrentTimestamp() {
    return new Date().getTime()
  }

  function isTokenExpired(savedTimestamp) {
    const currentTime = getCurrentTimestamp()
    const tokenAge = (currentTime - savedTimestamp) / (60 * 60 * 1000)
    //const oneDayInMilliseconds = 24 * 60 * 60 * 1000
    return tokenAge > 24
  }

  const { mutate } = useUpdateSchedule()

  const onSaveSchedule = async () => {
    mutate({ url: PMScheduleData.url })
  }

  const initiateSockets = async () => {
    if (!isUnauthFlow) {
      if (data?.accessToken) {
        const queryParams = `token=${data?.accessToken}`
        createWebsocket(queryParams)
      }
    } else {
      const chatToken = localStorage.getItem("chat-token")
      const tokenTimestamp = localStorage.getItem("chat-token-timestamp")
      if (!chatToken || !tokenTimestamp || isTokenExpired(parseInt(tokenTimestamp))) {
        const { token } = await IntegrationHelpMessages.getChatToken()
        const currentTimestamp = getCurrentTimestamp()
        localStorage.setItem("chat-token", token)
        localStorage.setItem("chat-token-timestamp", currentTimestamp.toString())
        const queryParams = `chatToken=${token}`
        createWebsocket(queryParams)
      } else {
        const queryParams = `chatToken=${chatToken}`
        createWebsocket(queryParams)
      }
    }
  }

  const sendJsonMessage = (data) => {
    if (websocket.readyState === WebSocket.OPEN) {
      websocket.send(JSON.stringify(data))
    }
  }

  const toggleCancelClick = () => {
    setCancelModal(!cancelModal)
    setIsSuggestionOpen(false)
    setIsInChat(false)
  }

  const openIsInChat = async () => {
    setIsInChat(true)
    if (websocket?.readyState === 3) {
      initiateSockets()
    }
  }
  const closeIsInChat = () => {
    setIsInChat(false)
    websocket.close()
  }

  const onReceiveMessage = (comments) => {
    const updatedChat = [...chat, ...(Array.isArray(comments) ? comments : [])]
    setChat(updatedChat)
  }

  new useCalendlyEventListener({
    onEventScheduled: () => {
      navigate("/aws-integration")
      onSaveSchedule()
    },
  })

  useEffect(() => {
    websocket.onclose = () => {
      setIsInChat(false)
    }
  }, [websocket])

  useEffect(() => {
    initiateSockets()
    if (isUnauthFlow)
      setTimeout(() => {
        setIsSuggestionOpen(true)
      }, 400)
  }, [data])

  return (
    <>
      <Button
        type="button"
        color="primary"
        size="md"
        className="round-btn text-white"
        id="help-popover"
        onClick={toggleCancelClick}
      >
        {cancelModal && <FontAwesomeIcon size="lg" icon={faTimes} id="helpIcon" className="help-icon pe-2" />}
        {!cancelModal && <FontAwesomeIcon size="sm" icon={faCommentAlt} id="helpIcon" className="help-icon pe-2" />}
        <span className="d-none d-lg-inline pb-1">Contact us</span>
      </Button>
      <Popover placement="left" isOpen={isSuggestionOpen} target="help-popover" className="position-relative">
        <FontAwesomeIcon
          size="lg"
          color="orange"
          icon={faSquareXmark}
          id="helpIcon"
          className="position-absolute top-0 start-1 cursor-pointer m-n1"
          onClick={() => setIsSuggestionOpen(false)}
        />
        <PopoverBody>Hi there 👋! Welcome to Billgist. Confused? Let us help.</PopoverBody>
      </Popover>
      <Popover placement="top-end" isOpen={cancelModal} toggle={toggleCancelClick} target="help-popover">
        {!isInChat ? (
          <>
            <PopoverHeader className="d-flex justify-content-between align-items-center bg-primary text-white fw-bold">
              <span></span>
              <span className="">Billgist</span>
              <Button color="primary" onClick={toggleCancelClick} className=" d-flex align-items-center text-white">
                <FontAwesomeIcon onClick={toggleCancelClick} icon={faTimes} />
              </Button>
            </PopoverHeader>

            <PopoverBody className="messages-space overflow-auto popover-body help-chat-body">
              Hi there👋! Not sure what to do? Reach out to us and we&apos;ll guide you through it.
              <img src={helpSvg} alt="Billgist Support" className="w-100 h-50 show-billgist-image" />
              <div className="d-flex justify-content-space-around mt-4">
                <Button onClick={openIsInChat} color="primary" className="w-50 m-1 text-light btn btn-primary">
                  Chat
                </Button>
                {PMScheduleStatus === "success" ? (
                  <>
                    <Button
                      onClick={() => setIsOpen(true)}
                      color="primary"
                      className="w-50 m-1 text-light btn btn-primary"
                    >
                      Schedule
                    </Button>
                    <PopupModal
                      url={PMScheduleData?.url}
                      prefill={{
                        email: data.email,
                        name: data.fullname,
                      }}
                      text="Schedule"
                      className="w-50 btn btn-primary text-light m-1"
                      open={isOpen}
                      onModalClose={() => setIsOpen(false)}
                      rootElement={document.getElementById("root")}
                    />
                  </>
                ) : (
                  <Button disabled color="primary" className="w-50 m-1 text-light btn btn-primary">
                    Schedule <Spinner size="sm" className="mx-1 text-white" />
                  </Button>
                )}
              </div>
            </PopoverBody>
          </>
        ) : (
          <>
            <PopoverHeader className="bg-primary text-white fw-bold d-flex justify-content-between align-items-center">
              <Button color="primary" onClick={closeIsInChat} className="d-flex align-items-center text-white">
                <FontAwesomeIcon icon={faArrowLeft} />
              </Button>
              <span>Conversation</span>
              <Button color="primary" onClick={toggleCancelClick} className="d-flex align-items-center text-white">
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </PopoverHeader>
            <MessagesSpace
              onReceiveMessage={onReceiveMessage}
              isWebsocketOpen={isWebsocketOpen}
              status={status}
              messages={chat}
              socket={websocket}
              sendMessage={sendJsonMessage}
              isUnauthFlow={isUnauthFlow}
              userData={data}
            />
          </>
        )}
      </Popover>
    </>
  )
}

export default IntegrationHelp
