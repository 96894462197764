import React, { Suspense, useContext, lazy } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import { Helmet } from "react-helmet"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import "./App.scss"
import Layout from "./components/Layout"
import SpinnerOverlay from "./components/SpinnerOverlay"
import { useAuthStatus } from "./utils/auth.utils"
import { UserContextProvider, UserContext } from "./contexts/UserContext"
import { ReactComponent as DataProcessingSVG } from "./assets/svgs/data-processing.svg"
import { BillingDataContextProvider } from "./contexts/BillingDataContext"
import IntegrationHelp from "./components/IntegrationHelp"
import RouterTracker from "./utils/GoogleAnalytics"
import CrazyEggTracker from "./utils/crazyEggAnalytics"

const SlackIntegration = lazy(() => import("./components/SlackIntegration"))
const LandingPage = lazy(() => import("./components/LandingPage"))
const AboutPage = lazy(() => import("./components/About"))
const Dashboard = lazy(() => import("./components/Dashboard"))
const Settings = lazy(() => import("./components/Settings"))
const AWSIntegration = lazy(() => import("./components/AWSIntegration"))
const AddIntegration = lazy(() => import("./components/AWSIntegration/AddIntegration"))
const AddExistingIntegration = lazy(() => import("./components/AWSIntegration/AddExistingIntegration"))
const EC2RightsizingRecommendation = lazy(() => import("./components/EC2RightsizingRecommendation"))
const Notification = lazy(() => import("./components/Notifications"))
const SlackSetup = lazy(() => import("./components/SlackSetup"))
const Subscription = lazy(() => import("./components/Subscriptions"))
const SignUp = lazy(() => import("./components/AuthPages/SignUp"))
const SignIn = lazy(() => import("./components/AuthPages/SignIn"))
const ResetPassword = lazy(() => import("./components/AuthPages/ResetPassword"))
const Confirmation = lazy(() => import("./components/Confirmation"))
const Unsubscribe = lazy(() => import("./components/Unsubscribe"))
const ThresholdConfirmation = lazy(() => import("./components/ThresholdConfirmation"))
const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy"))
const CreditsInput = lazy(() => import("./components/CreditsInput"))
const Team = lazy(() => import("./components/Team"))
const NewTeam = lazy(() => import("./components/Teams/NewTeam"))
const Organization = lazy(() => import("./components/Organization"))
const SmoothScrollButton = lazy(() => import("./components/ScrollToTop"))
const IdleEC2Confirmation = lazy(() => import("./components/IdleEC2Confirmation"))
const BudgetAlertConfirmation = lazy(() => import("./components/BudgetAlertConfirmation"))
const OrganizationMember = lazy(() => import("./components/Organization/OrganizationMember"))
// const IntegrationAnomalies = lazy(() => import("./components/AnomalyDetection"))

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY)

const SuspenseFallback = () => (
  <div className="d-flex align-items-center justify-content-center vh-100">
    <SpinnerOverlay show />
  </div>
)

const Protect = ({ element, condition, to }) => (condition ? <Navigate replace to={to} /> : element)

const AuthenticatedApp = ({ emailVerified }) => {
  const { data } = useContext(UserContext),
    isMember = data.role === "member",
    isOwner = data?.isOwner
  return (
    <Elements stripe={stripePromise}>
      <Layout authenticated>
        <Suspense fallback={<SuspenseFallback />}>
          <div className="help-button">
            <IntegrationHelp />
            <SmoothScrollButton />
          </div>
          <Routes>
            <Route
              exact
              path="/settings"
              element={
                <Settings
                  isVerifiedUser={emailVerified && data.email && data.email !== process.env.REACT_APP_DEMO_USER}
                />
              }
            />
            {!emailVerified ? (
              <Route path="*" element={<Navigate to="/settings" replace />} />
            ) : (
              <>
                <Route path={process.env.PUBLIC_URL + "/blog"} />
                <Route
                  exact
                  path="/dashboard"
                  element={
                    <BillingDataContextProvider>
                      <Dashboard />
                    </BillingDataContextProvider>
                  }
                />
                <Route
                  path="/notification"
                  element={
                    <Protect
                      condition={isMember}
                      to="/dashboard"
                      element={
                        <BillingDataContextProvider>
                          <Notification />
                        </BillingDataContextProvider>
                      }
                    />
                  }
                />
                <Route
                  path="/slack-setup"
                  element={
                    <Protect
                      condition={isMember}
                      to="/dashboard"
                      element={
                        <BillingDataContextProvider>
                          <SlackSetup />
                        </BillingDataContextProvider>
                      }
                    />
                  }
                />
                <Route path="/organization" element={<Protect to="/dashboard" element={<Organization />} />} />
                <Route
                  path="/organization-member/:slug"
                  element={<Protect to="/dashboard" element={<OrganizationMember />} />}
                />
                <Route path="/teams/:slug" element={<Protect to="/dashboard" element={<Team />} />} />
                <Route
                  path="/new-team"
                  element={<Protect condition={isMember} to="/dashboard" element={<NewTeam />} />}
                />
                <Route
                  path="/confirmation/:token"
                  element={<Protect condition={isMember} to="/dashboard" element={<Confirmation />} />}
                />
                <Route
                  path="/unsubscribe/:token"
                  element={<Protect condition={isMember} to="/dashboard" element={<Unsubscribe />} />}
                />
                <Route
                  path="/threshold-confirmation/:token"
                  element={<Protect condition={isMember} to="/dashboard" element={<ThresholdConfirmation />} />}
                />
                <Route
                  path="/idle-ec2-confirmation/:token"
                  element={<Protect condition={isMember} to="/dashboard" element={<IdleEC2Confirmation />} />}
                />
                <Route
                  path="budget-alert-confirmation/:token"
                  element={<Protect condition={isMember} to="/dashboard" element={<BudgetAlertConfirmation />} />}
                />
                <Route
                  path="/privacy-policy"
                  element={<Protect condition={isMember} to="/dashboard" element={<PrivacyPolicy isAuthed />} />}
                />
                <Route
                  exact
                  path="/aws-integration"
                  element={
                    <Protect
                      condition={isMember && !isOwner}
                      to="/dashboard"
                      element={<AWSIntegration heroSVG={<DataProcessingSVG />} />}
                    />
                  }
                />
                <Route
                  exact
                  path="/aws-integration/setup"
                  element={<Protect condition={isMember && !isOwner} to="/dashboard" element={<AddIntegration />} />}
                />
                <Route
                  exact
                  path="/aws-integration/existing-stack"
                  element={
                    <Protect condition={isMember && !isOwner} to="/dashboard" element={<AddExistingIntegration />} />
                  }
                />
                <Route
                  exact
                  path="/ec2-rightsizing-recommendation"
                  element={
                    <Protect
                      condition={isMember && !isOwner}
                      to="/dashboard"
                      element={<EC2RightsizingRecommendation />}
                    />
                  }
                />
                <Route
                  path="/subscription"
                  element={<Protect condition={isMember && !isOwner} to="/dashboard" element={<Subscription />} />}
                />
                <Route
                  path="/credits"
                  element={<Protect condition={isMember && !isOwner} to="/dashboard" element={<CreditsInput />} />}
                />
                <Route
                  path="/slack-integrate"
                  element={<Protect condition={isMember && !isOwner} to="/dashboard" element={<SlackIntegration />} />}
                />
                {/* <Route
                  path="/cost-anomalies"
                  element={
                    <Protect
                      condition={isMember && !isOwner}
                      to="/dashboard"
                      element={
                        <BillingDataContextProvider>
                          <IntegrationAnomalies />
                        </BillingDataContextProvider>
                      }
                    />
                  }
                /> */}
                <Route path="*" element={<Navigate to="/dashboard" replace />} />
              </>
            )}
          </Routes>
        </Suspense>
      </Layout>
    </Elements>
  )
}

const UnauthenticatedApp = () => (
  <Suspense fallback={<SuspenseFallback />}>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Billgist - monitor your AWS billing expenses</title>
      <meta
        name="description"
        content="Billgist help users monitor their AWS cost and make
        sure that it does not exceed without alerting them."
      />
      <meta property="og:url" content="https://www.billgist.com" />
      <meta property="og:title" content="Billgist - monitor your AWS billing expenses" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@billgistdotcom" />
      <meta name="twitter:creator" content="@billgistdotcom" />
      <meta name="twitter:url" content="https://www.billgist.com" />
      <meta name="twitter:title" content="Billgist - monitor your AWS billing expenses" />
      <meta property="og:image" content="https://www.billgist.com/og_tag.png" />
      <meta name="keywords" content="aws cost, aws billing, cost monitoring, aws cost alerts, aws cost analysis" />
      <link rel="canonical" href="https://www.billgist.com" />
    </Helmet>
    <Routes>
      <Route exact path="/" element={<LandingPage />} />
      <Route exact path="/about" element={<AboutPage />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route exact path="/sign-in" element={<SignIn />} />
      <Route path="/sign-in/reset" element={<ResetPassword />} />
      <Route path="/confirmation/:token" element={<Confirmation />} />
      <Route path="/unsubscribe/:token" element={<Unsubscribe />} />
      <Route path="/threshold-confirmation/:token" element={<ThresholdConfirmation />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path={process.env.PUBLIC_URL + "/blog"} />
      <Route path="*" element={<Navigate replace to="/sign-in" />} />
    </Routes>
  </Suspense>
)

const App = () => {
  RouterTracker()
  const [authorize, loading, emailVerified] = useAuthStatus()

  return (
    <>
      <CrazyEggTracker />
      {loading ? (
        <SuspenseFallback />
      ) : authorize ? (
        <UserContextProvider>
          <AuthenticatedApp emailVerified={emailVerified} />
        </UserContextProvider>
      ) : (
        <UnauthenticatedApp />
      )}
    </>
  )
}

export default App
